<template>
    <div>
        <div>
            <div
                class="wraptimepickerRetail"
                v-bind:class="{ fixedHeaderStyle: fixedHeader }"
            >
                <TimeSelectShopingMall
                    @changeDateRange="changeDateRange"
                    @changeDateRangeString="changeDateRangeString"
                    @changeCompareText="changeCompareText"
                    @changeCompareType="changeCompareType"
                    @changeListDateRange="changeListDateRange"
                ></TimeSelectShopingMall>
                <div class="wrapDownload" @click="downloadExcel">
                    <div class="custom-button">
                        {{ $t("Report.DownloadReport") }}
                        <span class="material-icons"> get_app </span>
                    </div>
                </div>
            </div>
            <!-- OVER VIEW -->
            <OverviewSection
                :dateRange="dateRange"
                :compareType="compareType"
                :compareText="compareText"
            />
            <!-- Tree map & Heatmap table -->
            <OverviewStoreSection
                :dateRange="dateRange"
                :compareType="compareType"
                :dateRangeString="dateRangeString"
            />
            <div class="ChooseStore mt-40">
                <span class="Title">
                    {{ $t("Report.titleForShopKPI").toUpperCase() }}
                </span>
                <div class="BTN-ChooseStore ml-16"
                    @click="chooseGroupOption()"
                    v-click-outside="handleClickOutSideGroup">
                    {{ choosenGroupName }}
                    <span class="material-icons"
                    >arrow_drop_down</span>
                    <div
                        ref="wrapGroupOption"
                        class="wrapOption"
                    >
                        <div
                            class="Option"
                            @click="changeOptionGroup(group)"
                            v-for="group in listGroup"
                            :key="group.id"
                        >
                            {{ group.name }}
                        </div>
                    </div>
                </div>
            </div>
            <section class="wrapRadarLine mt-24">
                <LineChartWrapper
                    :dateRange="dateRange"
                    :choosenGroupID="choosenGroupID">
                </LineChartWrapper>
                <AttributeSection :dateRange="dateRange" :choosenGroupID="choosenGroupID"/>
            </section>
            <section class="attribute-container mt-24">
                <HeatmapSection :dateRange="dateRange" :choosenGroupID="choosenGroupID"/>
                <RetailDetailReportRadarChart 
                    :dateRange="dateRange"
                    :choosenGroupID="choosenGroupID"
                />
            </section>
            
        </div>
    </div>
</template>

<script>
import { Api } from "@/api/index";
import * as moment from "moment-timezone";
import {
    TimeSelectShopingMall,
    OverviewSection,
    OverviewStoreSection,
    RetailDetailReportRadarChart,
    LineChartWrapper,
    HeatmapSection,
    AttributeSection,
} from "@/pages"

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

const getListGroup = Api.get("getListGroupV2");
const exportDataPeopleCounting = Api.get("exportDataPeopleCounting")

export default {
    name: "TabReport",
    components: {
        TimeSelectShopingMall,
        OverviewSection,
        OverviewStoreSection,
        RetailDetailReportRadarChart,
        LineChartWrapper,
        HeatmapSection,
        AttributeSection,
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    mounted() {
        if (localStorage.lang == "en") {
            this.language = "en";
        }
        window.addEventListener("scroll", this.handleScroll);
        getListGroup.get().then(response => {
            let listGroup = response.data.listResponse;
            this.listGroup = listGroup;
            listGroup.unshift({ id: "", name: this.$t("dashboard.All") });
            this.choosenGroupID = this.listGroup[0].id
            this.choosenGroupName = this.$t("dashboard.All")
        });

    },
    methods: {
        handleScroll() {
            if (window.scrollY > 200) {
                this.fixedHeader = true;
            } else {
                this.fixedHeader = false;
            }
        },
        chooseGroupOption() {
            this.$refs["wrapGroupOption"].style.position = "absolute";
            this.$refs["wrapGroupOption"].style.display = "block";
            this.$refs["wrapGroupOption"].style.visibility = "visible";
        },
        handleClickOutSideGroup() {
            this.$refs["wrapGroupOption"].style.display = "none";
            this.$refs["wrapGroupOption"].style.visibility = "hidden";
        },
        changeOptionGroup(group) {
            let self = this;
            setTimeout(() => {
                self.handleClickOutSideGroup();
            }, 100);
            this.choosenGroupID = group.id;
            this.choosenGroupName = group.name;
        },
        changeDateRange(val) {
            this.dateRange = val
            let dateDelta = (this.dateRange.dateEnd - this.dateRange.dateStart) / 86400
            if (dateDelta < 15) {
                this.isAverage = false
            }
        },
        changeDateRangeString(val) {
            this.dateRangeString = val
        },
        changeCompareType(val) {
            this.compareType = val
        },
        changeCompareText(val) {
            this.compareText = val
        },
        changeListDateRange(val) {
            this.listDateRange = val
        },
        downloadExcel() {
            exportDataPeopleCounting
                .get(
                    this.dateRange.dateStart,
                    this.dateRange.dateEnd,
                    "",
                    localStorage.lang
                )
                .then(response => {
                    let all = "All"
                    if (localStorage.lang == "vn") {
                        all = "Tất cả"
                    }
                    let fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        })
                    );
                    let fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    let fileName = all +
                        "_" +
                        this.dateRangeString.dateStart.replaceAll("/", "-") +
                        " - " +
                        this.dateRangeString.dateEnd.replaceAll("/", "-")
                    fileLink.download = fileName;

                    fileLink.click();
                });
        },
    },
    data() {
        let self = this
        return {
            fixedHeader: false,
            lang: localStorage.lang=="vn" ? "vi" : "en",
            dateRange: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("X")
            },
            dateRangeString: {
                dateStart: moment()
                    .subtract(7, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .format("DD/MM/YYYY")
            },
            compareType: "normal",
            choosenGroupName: "",
            choosenGroupID: "",
            compareText: self.$t("Report.CompareToThePriorSevenDays")
        }
    }
};
</script>
<style lang="scss">
.fixedHeaderStyle {
    position: fixed;
    top: -40px !important;
    left: 120px;
    width: calc(100% - 160px) !important;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    padding: 20px 24px;
}
.wraptabs {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    padding: 20px 24px;
    height: 80px;
    left: 103px;
    top: 124px;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .btn-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        width: 104px;
        height: 40px;
        border-radius: 8px;
        background-color: #fff;
        border: solid 0px;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
    }
}
.wraptimepickerRetail {
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    z-index: 99999999999999;
    .left-menu {
        display: flex;
        align-items: center;
    }
    .title {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-right: 32px;
    }
    .wrapDownload {
        display: flex;
        justify-content: flex-end !important;
        float: right !important;
        align-items: center;
        position: relative;
        cursor: pointer;
        .custom-button {
            padding: 9px 16px;
            display: flex;
            gap: 8px;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            align-items: center;
            line-height: 20px;
            color: #276EFF;
            border-radius: 8px;
            border: solid 1px #276EFF;
            margin-left: 16px;
            &:hover {
                .wrapOption {
                    visibility: visible;
                }
            }
        }
    }
}

.ChooseStore {
    display: flex;
    align-items: center;
    .Title {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        color: #1F1F1F;
    }
    .BTN-ChooseStore {
        border: 1px solid #8C8C8C;
        border-radius: 8px;
        padding: 16px 24px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #434343;
        height: 56px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        .wrapOption {
            overflow: auto;
            background: #ffffff;
            box-sizing: border-box;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            position: absolute;
            width: 250px !important;
            top: 60px;
            z-index: 9999;
            // visibility: hidden;
            max-height: 312px;
            overflow: auto;
            .Option {
                padding: 16px;
                font-family: "Roboto";
                font-size: 14px;
                line-height: 150%;
                color: #333333;
                min-width: 180px;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                    background-color: #f6f9ff;
                }
            }
        }
        .material-icons {
            font-size: 16px
        }
    }
}
.wrapRadarLine {
    display: grid;
    height: 532px;
    width: 100%;
    grid-template-columns: 1fr 516px;
    row-gap: 0;
    column-gap: 24px;
}

.attribute-container {
    display: grid;
    height: 532px;
    width: 100%;
    grid-template-columns: 1fr 516px;
    row-gap: 0;
    column-gap: 24px;
}
</style>